<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.username" placeholder="请输入登录名" style="width: 180px;" clearable/>
      <el-date-picker v-model="dateArr" type="datetimerange" :default-time="['00:00:00','23:59:59']" align="right" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <el-select v-model="listQuery.result" placeholder="全部" style="width: 120px" clearable>
        <el-option label="成功" value="1"></el-option>
        <el-option label="失败" value="0"></el-option>
      </el-select>
      <el-input v-model="listQuery.ip" placeholder="请输入IP地址" style="width: 180px;" clearable/>
      <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="操作人登录账号" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.username }}
        </template>
      </el-table-column>
      <el-table-column label="被操作对象" min-width="180" align="center">
        <template slot-scope="scope">
          {{ scope.row.request_url }}
        </template>
      </el-table-column>
      <el-table-column label="操作内容" min-width="180" align="center">
        <template slot-scope="scope">
          {{ scope.row.content }}
        </template>
      </el-table-column>
      <el-table-column label="操作结果" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.result==1?'成功':'' }}
        </template>
      </el-table-column>
      <el-table-column label="请求参数" min-width="240" align="center">
        <template slot-scope="scope">
          {{ scope.row.request_data }}
        </template>
      </el-table-column>
      <el-table-column label="方式" min-width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.request_method }}
        </template>
      </el-table-column>
      <el-table-column label="IP地址" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.ip }}
        </template>
      </el-table-column>
      <el-table-column label="操作时间" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      admins: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        username: "",
        ip: "",
        start_time: "",
        end_time: "",
        result: ""
      },
      dateArr: null,
    };
  },
  created() {
    this.getList();
    this.getAdminList();
  },
  mounted() {},
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_time = newVal[0];
        this.listQuery.end_time = newVal[1];
      } else {
        this.listQuery.start_time = "";
        this.listQuery.end_time = "";
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/adminLog/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getAdminList() {
      request({
        url: "/api/backend/admin/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000
        }
      }).then(response => {
        this.admins = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.floor-container {
  display: inline-block;
  width: 160px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 25px;
}
</style>
